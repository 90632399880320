import React from "react";
import { Link } from "gatsby";
import ReactWOW from "react-wow";
import { StaticImage } from "gatsby-plugin-image";

let scientist1 = "../../assets/images/scientist/scientist1.png";
let scientist2 = "../../assets/images/scientist/scientist2.png";
let scientist3 = "../../assets/images/scientist/scientist3.png";
let scientist4 = "../../assets/images/scientist/scientist4.png";
let scientist5 = "../../assets/images/scientist/scientist5.png";
let scientist6 = "../../assets/images/scientist/scientist6.png";
let scientist7 = "../../assets/images/scientist/scientist7.png";
let scientist8 = "../../assets/images/scientist/scientist8.png";
let scientist9 = "../../assets/images/scientist/scientist9.png";

const TeamStyleOne = () => {
  return (
    <div className="scientist-area bg-f1f8fb pt-100 pb-70">
      <div className="container">
        <div className="row">
          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="image">
                      <StaticImage placeholder="blurred" layout="fullWidth" src={scientist1} alt="Nikola Alexandrov" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Nikola Alexandrov</h3>
                      <span>CEO & Co-founder</span>

                      <ul className="social  mb-2">
                        <li>
                          <Link to="https://www.linkedin.com/in/nikola-alexandrov-12b73a9/" className="d-block text-center" target="_blank">
                            <i className="bx bxl-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                      <p>
                        CEO of LockTrip.com - a leading blockchain travel project with 2.1M integrated hotels in 190+ countries through contracts with 15 of the biggest travel suppliers in the world. Seven years of experience with high-frequency algorithmic trading on high liquidity global markets.
                      </p>
                      <p>Co-founder of the Bitcoin7 exchange that in 2011 had been one of the first exchanges in the world reaching third place in global volumes. CEO of theSEO.com agency.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2 mb-3 mb-sm-0">
                    <div className="image">
                      <StaticImage placeholder="blurred" class="rounded" layout="fullWidth" src={scientist2} alt="Hristo Tenchev" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Hristo Tenchev</h3>
                      <span>Co-founder</span>

                      <ul className="social mb-2">
                        <li>
                          <Link to="https://www.linkedin.com/in/hristotenchev/" className="d-block text-center" target="_blank">
                            <i className="bx bxl-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                      <p>Co-founder of LockTrip.com and Bitcoin7. Founder of xs-software.com, one of the most successful gaming companies in Europe with more than 50M registered players worldwide.</p>
                      <p>Co-founder at Softuni.bg - an innovative IT education center with more than 100,000 software engineering students for its three years history, also pioneering blockchain education in Bulgaria.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="image">
                      <StaticImage placeholder="blurred" layout="fullWidth" src={scientist3} alt="Florian Pfeiffer" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Florian Pfeiffer</h3>
                      <span>CCO &amp; Partner</span>

                      <ul className="social mb-2">
                        <li>
                          <Link to="https://www.linkedin.com/in/florian-pfeiffer-a95a21161" className="d-block text-center" target="_blank">
                            <i className="bx bxl-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                      <p>
                        Florian is an amazing addition to the human capital of Hydra. His unique analytical skills combined with powerful emotional intelligence and an innate ability to get into the finest details of any task makes him the best person to take charge of the communications strategy of
                        the company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="image">
                      <StaticImage placeholder="blurred" layout="fullWidth" src={scientist7} alt="Nevena Petrova" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Nevena Petrova</h3>
                      <span className={"mb-3 d-block"}>Business Development</span>

                      <p>
                        15 years of practice as a team leader and 4 years of experience with blockchain and innovation technologies. Nevena is capable of building excellent relationships with business partners and handles complex tasks professionally. Her enthusiasm about the project vision reflects
                        well on everything she does.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="image">
                      <StaticImage placeholder="blurred" layout="fullWidth" src={scientist9} alt="Ivan Ivanov" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Ivan Ivanov</h3>
                      <span className={"mb-3 d-block"}>Senior Developer and Team Lead</span>

                      <p>
                        Team Lead and Solution Architect since the project launched in 2017. Passionate and dedicated towards continuous team-advancement at work through self-improvement and learning. Lecturer at numerous conferences and educational courses where he elaborates on novel ideas around
                        existing technologies, as well as develops innovation technology concepts for various use cases.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="image">
                      <StaticImage placeholder="blurred" layout="fullWidth" src={scientist4} alt="Todor Yalamov" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Todor Yalamov</h3>
                      <span className={"mb-3 d-block"}>Senior Engineer</span>

                      <p>
                        Todor Yalamov has more than 20 years of experience as a software engineer. He has robust knowledge on networking, telecommunications, and security with a master degree in Computer Science from Technical University of Sofia, Todor has worked for some of the leading companies
                        like Telco System and Minerva Networks. The recent years his focus has shifted entirely into Blockchain and Machine Learning.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="image">
                      <StaticImage placeholder="blurred" layout="fullWidth" src={scientist5} alt="Rosen Krumov" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Rosen Krumov</h3>
                      <span className={"mb-3 d-block"}>Senior Engineer</span>

                      <p>More than three years of professional blockchain expertise - working with Ethereum smart contracts, Ethereum virtual machine, Bitcoin protocol. Currently focused on the Artificial intelligence and Blockchain field</p>
                      <p>Worked for two years on large-scale projects for the biggest automotive companies in the world.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="image">
                      <StaticImage placeholder="blurred" layout="fullWidth" src={scientist8} alt="Rashin Pothan" />
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="content">
                      <h3>Rashin Pothan</h3>
                      <span className={"mb-3 d-block"}>UX Developer &amp; Frontend Engineer</span>

                      <p>Rashin has been working with the team since 2019 as an UX developer and Frontend Engineer. He is skilled in designing and building intuitive user interface and scaleable frontend apps, and has been involved in the UI/UX transformation of LockTrip web and mobile apps.</p>
                      <p>He is the founder of 7thpillar Infotech, and is passionate about building digital products that solves business problems.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>

          <ReactWOW delay="0.5s" animation="fadeInLeft">
            <div className="col-sm-12">
              <div className="single-scientist-box">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="content">
                      <h6>Additional Credits for significant members who have been part of the development team</h6>
                      <ul className="m-0">
                        <li>Prof. Svetlin Nakov</li>
                        <li>Elian Kurtenkov</li>
                        <li>Aleksandar Aramov</li>
                        <li>Galin Ivanov</li>
                        <li>Softuni Establishment</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </div>
  );
};

export default TeamStyleOne;
