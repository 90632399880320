import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import TeamStyleOne from "../components/Team/TeamStyleOne";
import { Helmet } from "react-helmet";
const Team = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Team | HydraChain</title>
        <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Team" homePageText="Home" homePageUrl="/" activePageText="Team" />
      <TeamStyleOne />
      <Footer />
    </Layout>
  );
};

export default Team;
